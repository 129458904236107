.App {
    text-align: center;
}

.App-header {
    background-color: #282c34;
    padding: 20px;
    color: white;
    text-align: left;
    border-bottom: 2px solid #61dafb;
}

.App-body {
    padding: 20px;
    text-align: left;
    background-color: #f5f5f5;
}

.skill-set {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.skill-set p {
    width: 20%;
    background-color: #61dafb;
    padding: 5px;
    margin: 5px;
    text-align: center;
    border-radius: 5px;
    color: #282c34;
}

.project {
    margin: 10px 0;
}
.chart-container{
 
    width: 60%;
    height: 600px;
  height: 400px;
    margin-left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
   


}
label{
    font-size: 40px;
    font-weight: bold;
    color: #282c34;
}
.chart-caption {
    margin-top: 7px;

    font-size: 14px;
    text-align: center;
  }
 .legendDiv{

margin-left: 20%;
 }
 @media only screen and (max-width: 600px) {
 
        .chart-container{
 
            width: 120%;
            height: 120%;
            height: 400px;
            margin-left: -30px;
        }
            
            
           
        
        
        }


 